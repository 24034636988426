import React, {useState, useEffect, useContext} from 'react';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';
import firebase from 'firebase/app';
import {Button} from '@material-ui/core';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles';
import Help from './components/Help';
import TabPanel from './components/TabPanel';
import BasicPanel from './components/BasicPanel';
import AdvancedPanel from './components/AdvancedPanel';
import styled from 'styled-components';
import {
  Tabs,
  Tab,
  Box,
  CssBaseline,
  AppBar,
  Typography,
} from '@material-ui/core';

const theme = createMuiTheme({
  palette: {
    primary: {main: '#4caf50'},
    type: 'dark',
  },
});

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function() {
  const [tabIndex, setTabIndex] = React.useState(0);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar position="static">
        <Tabs
          value={tabIndex}
          onChange={(e, newValue) => {
            setTabIndex(newValue);
          }}
          aria-label="simple tabs example">
          <Tab label="Basic" {...a11yProps(0)} />
          <Tab label="Advanced" {...a11yProps(1)} />
          <Tab label="Help" {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      <TabPanel value={tabIndex} index={0}>
        <BasicPanel />
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <AdvancedPanel />
      </TabPanel>
      <TabPanel value={tabIndex} index={2}>
        <Help />
      </TabPanel>
    </ThemeProvider>
  );
}
