import * as d3 from 'd3';

const VUE_APP_PLOT_C_SHOWSCALE = false;
let VUE_APP_DEPRESSION_COLOR = 'rgba(0,0,250,0.75)';
const VUE_APP_AB_DEPRESSION_COLOR = 'rgba(0,0,250,0.75)';
let VUE_APP_SIMULATION_COLOR = 'rgba(0,200,0,1)';
const VUE_APP_CHORD_COLOR = 'rgba(255,255,255,0.2)';
const VUE_APP_PLOTA_CHORD_COLOR = 'rgba(255,255,255,1)';
const VUE_APP_PLOTB_CHORD_COLOR = 'rgba(255,255,255,0.7)';
const VUE_APP_SCALE_MIN = 10;
const VUE_APP_SCALE_MAX = 100;
const VUE_APP_CENTER_ANNOTATION = 'true';

function arrayRotateOne(arr, reverse) {
  if (reverse) arr.unshift(arr.pop());
  else arr.push(arr.shift());
  return arr;
}

function argwhere_lt(arr, value) {
  /* return the indices that match the condition
     arr[i] < value
     */
  return arr.reduce(function(a, e, i) {
    if (e < value) a.push(i);
    return a;
  }, []);
}

function argwhere_lteq(arr, value) {
  /* return the indices that match the condition
     arr[i] <= value
     */
  return arr.reduce(function(a, e, i) {
    if (e <= value) a.push(i);
    return a;
  }, []);
}

function argwhere_gt(arr, value) {
  /* return the indices that match the condition
     arr[i] > value
     */
  return arr.reduce(function(a, e, i) {
    if (e > value) a.push(i);
    return a;
  }, []);
}

function generate_splits(my_mask, value = 1) {
  //copy my_mask and rotate by one index
  let my_mask2 = my_mask.slice(0, my_mask.length);

  arrayRotateOne(my_mask2, false);

  let diff_mask = my_mask2.map(function(item, index) {
    // In this case item corresponds to currentValue of array a,
    // using index to get value from array my_mask
    return item - my_mask[index];
  });

  return argwhere_gt(diff_mask, value);
}

function closest(num, arr) {
  var curr = arr[0];
  var best_idx = 0;

  var diff = Math.abs(num - curr);

  for (var val = 0; val < arr.length; val++) {
    var newdiff = Math.abs(num - arr[val]);

    if (newdiff < diff) {
      diff = newdiff;
      best_idx = val;
    }
  }

  return best_idx;
}

function get_AB_trace_style(my_color = 'green') {
  // negative is blue, positive is green
  let my_fill = VUE_APP_SIMULATION_COLOR;

  if (my_color === 'blue') my_fill = VUE_APP_AB_DEPRESSION_COLOR;

  return {
    fill: 'tozeroy',
    fillcolor: my_fill,
    hoverinfo: 'none',
    showlegend: false,
    line: {color: my_color},
    mode: 'none',
    name: 'build heat lines chart A',
    type: 'scatter',
  };
}

function get_chart_C_colorscale(
  depression_fraction,
  threshold_fraction,
  saturation_fraction,
  depression_color,
  simulation_color,
) {
  // create a colorscale with a small fraction at the bottom in my_color, then a linear
  //   gradient black to green
  let zero_color = 'rgba(0,0,0,1)';

  var z_scale = d3
    .scaleLinear()
    .range([zero_color, simulation_color])
    .domain([100 * threshold_fraction, 100 * Math.min(saturation_fraction, 1)]);

  var cscale = [];
  var cincrement = 5;

  for (var i = 0; i < 21; i++) {
    let frac = (i * cincrement) / 100;

    let frac_plus = ((i + 1) * cincrement) / 100;

    if (frac < depression_fraction) {
      cscale.push([frac.toFixed(2), depression_color]);

      if (frac_plus >= depression_fraction)
        cscale.push([depression_fraction.toFixed(10), zero_color]);

      if (frac_plus >= threshold_fraction)
        cscale.push([threshold_fraction.toFixed(10), z_scale(cincrement * i)]);
    } else if (frac > depression_fraction && frac < threshold_fraction) {
      cscale.push([frac.toFixed(2), zero_color]);

      if (frac_plus >= threshold_fraction)
        cscale.push([threshold_fraction.toFixed(10), z_scale(cincrement * i)]);
    } else if (frac >= saturation_fraction) {
      if (frac - cincrement / 100 < saturation_fraction)
        cscale.push([saturation_fraction.toFixed(10), simulation_color]);
      cscale.push([frac.toFixed(2), simulation_color]);
    } else cscale.push([frac.toFixed(2), z_scale(cincrement * i)]);
  }

  return cscale;
}

function initialChord(eyespace_data) {
  let r = eyespace_data.diameter / 2.0 - 1.0;
  let x = r * Math.cos(eyespace_data.rotation[2]);
  let y = r * Math.sin(eyespace_data.rotation[2]);
  return [x, y];
}

function get_r(x, y) {
  return Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));
}

function get_theta(x, y) {
  //special case where we want 0 degrees not NaN
  if (x == 0 && y == 0) return 0;

  let radians = Math.atan2(y, x);
  let degrees = Math.round((radians * 180) / Math.PI);

  if (degrees < 0) {
    return degrees + 360;
  }

  return degrees;
}

function generate_AB_traces(x, y, pos_mask, neg_mask, yref) {
  // chart_A and chart B are scatter plot
  // this method helps generate the traces (positive and negative)
  // for the charts

  let splits = generate_splits(pos_mask);

  let neg_splits = generate_splits(neg_mask);

  let pyd = {};
  let nyd = {};

  if (splits.length == 0) {
    if (pos_mask.length > 0) pyd[0] = pos_mask;
  } else if (splits.length == 1) {
    pyd[0] = pos_mask.slice(0, splits[0] + 1);
    pyd[1] = pos_mask.slice(splits[0] + 1, pos_mask.length);
  } else {
    Object.entries(splits).forEach(entry => {
      let ii = parseInt(entry[0]);
      let split = entry[1];

      if (ii == 0) {
        pyd[ii] = pos_mask.slice(0, split + 1);
        pyd[ii + 1] = pos_mask.slice(split + 1, splits[ii + 1] + 1);
      } else if (ii == splits.length - 1) {
        pyd[ii + 1] = pos_mask.slice(split + 1, pos_mask.length);
      } else {
        let end_pt = splits[ii + 1];
        let start_pt = splits[ii] + 1;

        pyd[ii + 1] = pos_mask.slice(start_pt, end_pt + 1);
      }
    });
  }

  if (neg_splits.length == 0) {
    if (neg_mask.length > 0) nyd[0] = neg_mask;
  }

  if (neg_splits.length == 1) {
    nyd[0] = neg_mask.slice(0, neg_splits[0] + 1);
    nyd[1] = neg_mask.slice(neg_splits[0] + 1, neg_mask.length);
  }

  let traces = [];

  let pt_dict = get_AB_trace_style();

  let nt_dict = get_AB_trace_style('blue');

  if (Object.keys(nyd).length != 0) {
    Object.values(nyd).forEach(split => {
      let ddict = {};

      Object.assign(ddict, nt_dict);

      //console.log('this is a negative split');
      //console.log(split);
      let end_pt = split[split.length - 1];

      ddict['x'] = x.slice(split[0], end_pt + 1);
      ddict['y'] = y.slice(split[0], end_pt + 1);
      ddict['yaxis'] = yref;
      traces.push(ddict);
    });
  }

  if (Object.keys(pyd).length === 0) {
    let pdict = {};
    Object.assign(pdict, pt_dict);
    let end = pos_mask.length - 1;

    pdict['x'] = x.slice(pos_mask[0], pos_mask[end] + 1);
    pdict['y'] = y.slice(pos_mask[0], pos_mask[end] + 1);
    //console.log('add positive trace');
    pdict['yaxis'] = yref;
    traces.push(pdict);
  } else {
    Object.values(pyd).forEach(split => {
      let pdict = {};

      Object.assign(pdict, pt_dict);
      //console.log('this is a pos_split ' +  split);
      let end_point = split[split.length - 1];

      pdict['x'] = x.slice(split[0], end_point + 1);
      pdict['y'] = y.slice(split[0], end_point + 1);
      pdict['yaxis'] = yref;
      //console.log('add positive trace');
      traces.push(pdict);
    });
  }

  //console.log(traces.length);
  return traces;
}

function get_c_shapes(x, y, delta, edata) {
  let shapes = [];

  let click_circle = {
    type: 'circle',
    xanchor: x,
    yanchor: y,
    x0: -delta,
    y0: -delta,
    x1: delta,
    y1: delta,
    xsizemode: 'pixel',
    ysizemode: 'pixel',
    xref: 'x',
    yref: 'y',
    fillcolor: VUE_APP_CHORD_COLOR,
    line: {
      color: VUE_APP_CHORD_COLOR,
      width: 3,
    },
  };

  let opposite_circle = {
    type: 'circle',
    xanchor: -x,
    yanchor: -y,
    x0: -delta,
    y0: -delta,
    x1: delta,
    y1: delta,
    xsizemode: 'pixel',
    ysizemode: 'pixel',
    xref: 'x',
    yref: 'y',
    fillcolor: VUE_APP_CHORD_COLOR,
    line: {
      color: VUE_APP_CHORD_COLOR,
      width: 3,
    },
  };

  let chord = {
    type: 'line',
    x0: x,
    y0: y,
    x1: -x,
    y1: -y,
    xref: 'x',
    yref: 'y',
    fillcolor: VUE_APP_CHORD_COLOR,
    line: {
      color: VUE_APP_CHORD_COLOR,
      width: 2,
    },
  };

  let stabAngle = edata.rotation[2];
  let stabPos = edata.diameter / 2.0;
  let stabilisation1 = {
    type: 'line',
    x0: (stabPos - 2) * Math.cos(stabAngle),
    y0: (stabPos - 2) * Math.sin(stabAngle),
    x1: (stabPos - 1) * Math.cos(stabAngle),
    y1: (stabPos - 1) * Math.sin(stabAngle),
    xref: 'x',
    yref: 'y',
    fillcolor: VUE_APP_CHORD_COLOR,
    line: {
      color: VUE_APP_CHORD_COLOR,
      width: 4,
    },
  };
  let stabilisation2 = {
    type: 'line',
    x0: (stabPos - 2) * Math.cos(stabAngle + Math.PI),
    y0: (stabPos - 2) * Math.sin(stabAngle + Math.PI),
    x1: (stabPos - 1) * Math.cos(stabAngle + Math.PI),
    y1: (stabPos - 1) * Math.sin(stabAngle + Math.PI),
    xref: 'x',
    yref: 'y',
    fillcolor: VUE_APP_CHORD_COLOR,
    line: {
      color: VUE_APP_CHORD_COLOR,
      width: 4,
    },
  };
  shapes.push(stabilisation1);
  shapes.push(stabilisation2);

  shapes.push(click_circle);
  shapes.push(opposite_circle);
  shapes.push(chord);

  return shapes;
}

function get_ab_interfaces(edata, r, theta, chartDiv) {
  let self = this;

  let x = edata.cylR;

  let theta_ia = theta;
  let theta_fa = (theta_ia + 180) % 360;

  let theta_ib = (theta + 90) % 360;
  let theta_fb = (theta_ib + 180) % 360;

  let ya = edata.cylZ[theta_ia % 180];
  let yb = edata.cylZ[theta_ib % 180];

  let ya_args = argwhere_lt(ya, 1e20);
  let yb_args = argwhere_lt(yb, 1e20);

  let max_ya_arg = Math.max(...ya_args);
  let max_yb_arg = Math.max(...yb_args);

  ya = ya.slice(ya_args[0], max_ya_arg);

  let xa = x.slice(ya_args[0], max_ya_arg);

  yb = yb.slice(yb_args[0], max_yb_arg);

  let xb = x.slice(yb_args[0], max_yb_arg);

  let ya_idx = closest(r, xa);
  let yb_idx = closest(r, xb);

  var za = ya[ya_idx];
  var zb = yb[yb_idx];

  let ymin = -10; //Based on issue #45

  let ymax = Math.max(Math.max(...ya), Math.max(...yb));

  ymax += 10; //Based on issue #45

  //let xmin = Math.min(Math.min(...xa), Math.min(...xb));
  //let xmax = Math.max(Math.max(...xa), Math.max(...xb));
  let xmin = Math.min(...edata.cylR);
  let xmax = Math.max(...edata.cylR);

  console.log('plotab', xmin, xmax);

  var dz = 3;

  //console.log('dz = ', dz);

  var nR = -r;
  var pR = r;

  let plota_interface = {
    pR: pR,
    nR: nR,
    x: xa,
    y: ya,
    z: za,
    dz: dz,
    theta_i: theta_ia,
    theta_f: theta_fa,
    div: chartDiv,
    xmin: xmin,
    xmax: xmax,
    ymin: ymin,
    ymax: ymax,
    chord_color: VUE_APP_PLOTA_CHORD_COLOR,
    yref: 'y3',
  };

  let plotb_interface = {
    pR: pR,
    nR: nR,
    x: xb,
    y: yb,
    z: zb,
    dz: dz,
    theta_i: theta_ib,
    theta_f: theta_fb,
    div: chartDiv,
    xmin: xmin,
    xmax: xmax,
    ymin: ymin,
    ymax: ymax,
    chord_color: VUE_APP_PLOTB_CHORD_COLOR,
    yref: 'y2',
  };

  return {a: plota_interface, b: plotb_interface};
}

function add_data(figures) {
  let data = [];

  if (figures.hasOwnProperty('c')) {
    data = figures.c.data;
  }
  data.push(...figures.b.data);

  data.push(...figures.a.data);

  return data;
}

function getCylZMax(data) {
  let maxRow = data.cylZ.map(function(row) {
    let idxs = argwhere_lt(row, 1e20);
    let myrow = row.slice(idxs[0], idxs[idxs.length - 1]);
    return Math.max(...myrow);
  });
  return Math.max(...maxRow);
}

function slice_figure(pdata) {
  let self = this;
  let x = pdata.x;

  let y = pdata.y;

  let xmin = pdata.xmin;

  let xmax = pdata.xmax;

  //console.log('xmin, xmax', xmin, xmax);

  var line_style = {color: pdata.chord_color, width: 2};

  var neg_mask = argwhere_lteq(y, 0);

  let pos_mask = argwhere_gt(y, 0);

  var traces = generate_AB_traces(x, y, pos_mask, neg_mask, pdata.yref);

  var layout = {
    xaxis: {
      showgrid: true,
      zeroline: true,
      showticklabels: false,
      color: 'grey',
      autorange: false,
      range: [xmin, xmax],
      gridcolor: 'rgba(211,211,211,0.3)',
    },
    yaxis: {
      side: 'right',
      showgrid: true,
      color: 'grey',
      ticklen: 3,
      zeroline: false,
      gridcolor: 'rgba(211,211,211,0.3)',
      range: [pdata.ymin, pdata.ymax],
    },
    legend: 'none',
    plot_bgcolor: 'black',
    paper_bgcolor: 'black',
    dragmode: false,
    //left and right margin are necessary to keep alignment with plot C
    margin: {t: 20, b: 20, l: 10, r: 25},
    annotations: [
      {
        x: xmin + 1,
        y: pdata.ymin,
        showarrow: false,
        text: pdata.theta_f.toString() + ' <sup>o</sup>',
        font: {color: 'white', size: 18},
        bgcolor: 'black',
        yref: pdata.yref,
      },
      {
        x: xmax - 1,
        y: pdata.ymin,
        showarrow: false,
        text: pdata.theta_i.toString() + ' <sup>o</sup>',
        font: {color: 'white', size: 18},
        bgcolor: 'black',
        yref: pdata.yref,
      },
      {
        x: 0,
        y: pdata.z,
        showarrow: false,
        text: Math.abs(2 * pdata.pR).toFixed(1) + ' mm',
        yanchor: 'bottom',
        font: {color: pdata.chord_color, size: 18},
        yref: pdata.yref,
      },
    ],
    shapes: [
      {
        type: 'line',
        x0: pdata.nR,
        y0: pdata.z,
        x1: pdata.pR,
        y1: pdata.z,
        line: line_style,
        yref: pdata.yref,
      },
      {
        type: 'line',
        x0: pdata.nR,
        y0: -pdata.dz,
        x1: pdata.nR,
        y1: pdata.dz,
        line: line_style,
        ysizemode: 'pixel',
        yanchor: pdata.z,
        yref: pdata.yref,
      },
      {
        type: 'line',
        x0: pdata.pR,
        y0: -pdata.dz,
        x1: pdata.pR,
        y1: pdata.dz,
        line: line_style,
        ysizemode: 'pixel',
        yanchor: pdata.z,
        yref: pdata.yref,
      },
    ],
  };

  return {data: traces, layout: layout};
}

function get_ab_figures(edata, r, theta) {
  let chartDiv = 'chart_C';
  let interfaces = get_ab_interfaces(edata, r, theta, chartDiv);

  let figa = slice_figure(interfaces.a);
  let figb = slice_figure(interfaces.b);

  return {a: figa, b: figb};
}

function get_rlens(edata) {
  let xsize = edata.x.length;
  let ysize = edata.y.length;

  let ztext = new Array(xsize);

  let z = edata.z;
  let x = edata.x;
  let y = edata.y;

  let xmid_pt = Math.floor(xsize / 2);
  let ymid_pt = Math.floor(ysize / 2);

  //console.log(xmid_pt, ymid_pt);

  let zlens = z[xmid_pt];

  let lens_mask = argwhere_lt(zlens, 1e20);

  let yminlens = y[lens_mask[0] - 1];

  let ymaxlens = y[lens_mask[lens_mask.length - 1] + 1];

  //console.log('lens y', Math.abs(yminlens), ymaxlens);
  //at x==0
  return Math.round(ymaxlens * 100) / 100;
}

function heatmap_figure(edata, depression_color, simulation_color) {
  let xsize = edata.x.length;
  let ysize = edata.y.length;

  let ztext = new Array(xsize);

  let z = edata.z;
  let x = edata.x;
  let y = edata.y;

  let xmid_pt = Math.floor(xsize / 2);
  let ymid_pt = Math.floor(ysize / 2);

  //console.log(xmid_pt, ymid_pt);

  let zlens = z[xmid_pt];

  let zmin = 0;
  let zmax = 0;

  for (let i = 0; i < xsize; i++) {
    ztext[i] = new Array(ysize);

    for (let j = 0; j < ysize; j++) {
      ztext[i][j] = z[i][j].toFixed(0);

      if (z[i][j] > 1e20) {
        z[i][j] = 0;
        ztext[i][j] = '+inf';
      }
    }
    let tempmin = Math.min(...z[i]);
    let tempmax = Math.max(...z[i]);

    if (tempmin < zmin) zmin = tempmin;
    if (tempmax > zmax) zmax = tempmax;
  }

  let zrange = zmax - zmin;

  let depression_fraction = Math.abs(zmin) / zrange;
  let threshold_fraction = depression_fraction + VUE_APP_SCALE_MIN / zrange;
  //colorscale to saturate about 100 micron
  let saturation_fraction = VUE_APP_SCALE_MAX / zrange;

  let colorscale = get_chart_C_colorscale(
    depression_fraction,
    threshold_fraction,
    saturation_fraction,
    depression_color,
    simulation_color,
  );

  let showscale = false;

  if (VUE_APP_PLOT_C_SHOWSCALE) showscale = true;

  let xmin = Math.min(...x);
  let xmax = Math.max(...x);

  var data = [
    {
      z: z,
      x: x,
      y: y,
      text: ztext,
      type: 'heatmap',
      opacity: 1,
      hoverinfo: 'text',
      colorscale: colorscale,
      showscale: showscale,
      //hoverinfo: 'none',
      colorbar: {
        thicknessmode: 'fraction',
        thickness: 0.02,
        xanchor: 'center',
        len: 0.7,
        lenmode: 'fraction',
        outlinewidth: 0,
      },
    },
  ];

  var layout = {
    plot_bgcolor: 'black',
    paper_bgcolor: 'black',
    dragmode: false,
    automargin: false,
    //left and right margin are necessary to keep alignment with plot A/B
    margin: {t: 0, b: 0, l: 10, r: 25},
    xaxis: {
      autorange: true,
      showgrid: false,
      showline: false,
      zeroline: false,
      ticks: '',
      showticklabels: false,
    },
    yaxis: {
      showgrid: false,
      showline: false,
      zeroline: false,
      ticks: '',
      showticklabels: false,
      scaleanchor: 'x',
      scaleratio: 1,
    },
    zaxis: {range: [zmin, zmax]},
    hovermode: 'closest',
  };

  if (VUE_APP_CENTER_ANNOTATION) {
    let annotations = [
      {
        text: zlens[ymid_pt].toFixed(0) + ' &mu;m',
        x: 0,
        y: 0,
        xref: 'x',
        yref: 'y',
        showarrow: false,
        font: {color: 'white', size: 18},
      },
    ];

    layout['annotations'] = annotations;
  }
  return {data: data, layout: layout};
}

export {
  get_rlens,
  get_r,
  get_theta,
  get_c_shapes,
  get_ab_figures,
  add_data,
  heatmap_figure,
  initialChord,
};
