/** @jsx jsx */
import React, {useState, useEffect, useContext} from 'react';
import NumberField from './NumberField';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import styled from 'styled-components';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import {jsx} from '@emotion/core';

let colStyle = {
  width: '50%',
  paddingRight: '10px',
  paddingLeft: '10px',
  paddingTop: '10px',
};

export default function(props) {
  const [cornealUnits, setCornealUnits] = useState(1);
  console.log(props.value)

  function roundTwo(input) {
    return Math.round(100 * input) / 100;
  }

  return (
    <div>
      <h3>Corneal Data</h3>

      <FormControl
        css={{
          width: '20%',
          float: 'left',
          marginLeft: '10px',
          marginBottom: '10px',
        }}>
        <InputLabel id="demo-simple-select-label">Corneal Units</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={cornealUnits}
          onChange={e => {
            setCornealUnits(e.target.value);
          }}>
          <MenuItem value={1}>mm</MenuItem>
          <MenuItem value={2}>diopters</MenuItem>
        </Select>
      </FormControl>

      <div css={{display: 'flex', flexWrap: 'wrap', width: '100%'}}>
        <div css={colStyle}>
          {cornealUnits === 1 ? (
            <NumberField
              value={props.value[0]['r'][0]}
              label="Flat R"
              suffix="mm"
              onChange={e => {
                let newParams = JSON.parse(JSON.stringify(props.value));
                let newVal = parseFloat(e.target.value);
                newParams[0]['r'][0] = newVal;
                props.setValue(newParams);
              }}
              min="4"
              max="12"
              step="0.05"
            />
          ) : (
            <NumberField
              value={337.5 / props.value[0]['r'][0]}
              label="Flat K"
              suffix="D"
              onChange={e => {
                let newParams = JSON.parse(JSON.stringify(props.value));
                let newVal = 337.5 / parseFloat(e.target.value);
                newParams[0]['r'][0] = newVal;
                props.setValue(newParams);
              }}
              min="30"
              max="100"
              step="0.05"
            />
          )}
        </div>
        <div css={colStyle}>
          <NumberField
            value={props.value[0]['e'][1]}
            label="Flat E"
            suffix=""
            onChange={e => {
              let newParams = JSON.parse(JSON.stringify(props.value));
              newParams[0]['e'][1] = parseFloat(e.target.value);
              props.setValue(newParams);
            }}
            min="0"
            max="2"
            step="0.05"
          />
        </div>

        <div css={colStyle}>
          {cornealUnits === 1 ? (
            <NumberField
              value={props.value[0]['r'][1]}
              label="Steep R"
              suffix="mm"
              onChange={e => {
                let newParams = JSON.parse(JSON.stringify(props.value));
                let newVal = parseFloat(e.target.value);
                newParams[0]['r'][1] = newVal;
                props.setValue(newParams);
              }}
              min="4"
              max="12"
              step="0.05"
            />
          ) : (
            <NumberField
              value={337.5 / props.value[0]['r'][1]}
              label="Steep K"
              suffix="D"
              onChange={e => {
                let newParams = JSON.parse(JSON.stringify(props.value));
                let newVal = 337.5 / parseFloat(e.target.value);
                newParams[0]['r'][1] = newVal;
                props.setValue(newParams);
              }}
              min="30"
              max="100"
              step="0.05"
            />
          )}
        </div>

        <div css={colStyle}>
          <NumberField
            value={props.value[0]['e'][0]}
            label="Steep E"
            suffix=""
            onChange={e => {
              let newParams = JSON.parse(JSON.stringify(props.value));
              newParams[0]['e'][0] = parseFloat(e.target.value);
              props.setValue(newParams);
            }}
            min="0"
            max="2"
            step="0.05"
          />
        </div>

        <div css={colStyle}>
          <NumberField
            value={props.flatAxis}
            label="Flat Axis"
            suffix="degrees"
            onChange={e => {
              console.log("Setting inside cornealdata")
              props.setFlatAxis(parseFloat(e.target.value));
            }}
            min="1"
            max="180"
            step="1"
          />
        </div>
      </div>
    </div>
  );
}
