/** @jsx jsx */
import {jsx} from '@emotion/core';
import React, {useState, useEffect, useContext} from 'react';
import img from '../static/logo.png';
import styled from 'styled-components';

const Wrapper = styled.div`
  margin: 50px;
  text-align: left;
  a {
  color: white;
  }
`;

export default function(props) {
  return (
    <Wrapper>
      <img src={img} alt="EyeSpace" style={{width: '300px'}} />
      <p>
        Proudly brought to you by{' '}
        <a target="_blank" href="https://eyespacelenses.com">
          EyeSpace™
        </a>
        .
      </p>
      <p>
        Designed in collaboration with Associate Professor{' '}
        <a
          target="_blank"
          href="https://www.flinders.edu.au/people/tony.phillips">
          Tony Phillips
        </a>
        , and practitioners and educators from around the world.
      </p>
      <p>
        This tool is designed to help teach RGP lens design and to aid you in
        practice.
      </p>
      <h2>Introduction</h2>
      <p>
        The general principles of RGP lens design are laid out in Chapter 9 of{' '}
        <a
          target="_blank"
          href="https://www.elsevierhealth.com.au/contact-lenses-9780702071683.html">
          Contact Lenses, 6th Edition (Anthony J. Philips)
        </a>
        . This program is designed to help students understand how the
        parameters of corneal curvature and eccentricity affect the design of an
        RGP lens and how, in turn, these change with the variations in curve
        widths and total diameter. The understanding of this is essential before
        moving on to more complex designs such as for Ortho-k lenses, for
        example.
      </p>
      <h2>Basic Design</h2>
      <ol css={{textAlign: 'left'}}>
        <li>
          Begin by entering the corneal data. If you wish, you can include
          corneal astigmatism by entering a different value for the Steep K.
        </li>
        <li>
          Enter your estimated Back Optic Zone Radius (BOZR, Zone 1), mid
          peripheral curve (Zone 2) and edge curve (Zone 3)
        </li>
        <li>
          You can then enter the eccentricity of these zones. 0.5 is a typical
          value.
        </li>
        <li>
          Next, set the zone diameters. The default values provide a good
          starting point.
        </li>
        <li>
          Press <b>Calculate</b> and the simulated fluorescein pattern will be
          shown on the right, and a cross section of the principal meridians is
          also shown.
        </li>
      </ol>
      <p />
      <p>
        The central Tear Layer Thickness (TLT) is labelled in the centre of
        fluorescein pattern.
      </p>
      <p>
        <b>The simulation is interactive</b>: you can move the mouse over the
        lens surface to show the TLT at any point. You can also click on the
        lens surface to show a cross section through that meridian.
      </p>
      <h2>Advanced Design</h2>
      Using this tab, you can enter the desired tear film thickness at each zone
      junction. Press <i>calculate</i> and it will automatically calculate the
      parameters for you.
      <h2>Want to see more?</h2>
      <p>
        Our commercial product{' '}
        <a target="_blank" href="https://eyespacelenses.com">
          EyeSpace™
        </a>{' '}
        includes the ability to import data from your corneal topographer and
        design lenses from true corneal height data. It also includes a complete
        range of lenses, including orthokeratology, and scleral lenses, and a
        complete ordering, track and trace and troubleshooting system. The
        software is free of charge through our official distributors.
      </p>
    </Wrapper>
  );
}
