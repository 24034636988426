import React, {useState, useEffect, useContext} from 'react';
import img from '../static/logo.png';
import {TextField, InputAdornment} from '@material-ui/core';

export default function(props) {
  return (
    <TextField
      v-text-field
      type="number"
      value={props.value}
      style={{width: '100%'}}
      onChange={e => {
        props.onChange(e);
      }}
      label={props.label}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">{props.suffix}</InputAdornment>
        ),
      }}
      inputProps={{
        min: props.min,
        max: props.max,
        step: props.step,
      }}
    />
  );
}
