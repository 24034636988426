/** @jsx jsx */
import React, {useState, useEffect, useContext} from 'react';
import NumberField from './NumberField';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import Delete from '@material-ui/icons/Delete';
import Add from '@material-ui/icons/Add';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {RS, Toric} from './Zone';
import {jsx} from '@emotion/core';

export default function(props) {
  let numMeridians = props.value[0]['r'].length;

  function addZone() {
    let newParams = props.value.slice();
    newParams.push(props.value[props.value.length - 1]);
    props.setValue(newParams);
  }

  function makeToric() {
    let newParams = [];
    for (let i = 0; i < props.value.length; i++) {
      let zone = {};
      zone['r'] = [props.value[i]['r'][0], props.value[i]['r'][0]];
      zone['e'] = [props.value[i]['e'][0], props.value[i]['e'][0]];
      zone['d'] = props.value[i]['d'];
      newParams.push(zone);
    }
    props.setValue(newParams);
  }

  function makeRS() {
    let newParams = [];
    for (let i = 0; i < props.value.length; i++) {
      let zone = {};
      zone['r'] = [props.value[i]['r'][0]];
      zone['e'] = [props.value[i]['e'][0]];
      zone['d'] = props.value[i]['d'];
      newParams.push(zone);
    }
    props.setValue(newParams);
  }

  return (
    <div>
      <h3>Lens Parameters</h3>
      <FormControl
        css={{
          width: '20%',
          float: 'left',
          marginLeft: '10px',
          marginBottom: '10px',
        }}>
        <InputLabel id="demo-simple-select-label">Lens Type</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={numMeridians}
          onChange={e => {
            if (e.target.value == 1) {
              makeRS();
            }
            if (e.target.value == 2) {
              makeToric();
            }
            console.log('Selected');
          }}>
          <MenuItem value={1}>RS</MenuItem>
          <MenuItem value={2}>Toric</MenuItem>
        </Select>
      </FormControl>

      {props.value.map((item, index) =>
        numMeridians == 1 ? (
          <RS
            key={index}
            index={index}
            vals={item}
            setVals={val => {
              let newParams = props.value.slice();
              newParams[index] = val;
              props.setValue(newParams);
            }}
            deleteZone={() => {
              let newParams = props.value.slice();
              newParams.splice(index, 1);
              props.setValue(newParams);
            }}
          />
        ) : (
          <Toric
            key={index}
            index={index}
            vals={item}
            setVals={val => {
              let newParams = props.value.slice();
              newParams[index] = val;
              props.setValue(newParams);
            }}
            deleteZone={() => {
              let newParams = props.value.slice();
              newParams.splice(index, 1);
              props.setValue(newParams);
            }}
          />
        ),
      )}

      <div
        css={{
          textAlign: 'right',
          marginBottom: '10px',
          marginTop: '20px',
        }}>
        <Button
          variant="contained"
          css={{
            width: '150px',
          }}
          onClick={e => {
            addZone();
          }}
          startIcon={<Add />}>
          Add Zone
        </Button>
      </div>
    </div>
  );
}
