/** @jsx jsx */
import {jsx} from '@emotion/core';
import React, {useState, useEffect, useContext} from 'react';
import img from '../static/logo.png';

export default function(props) {
  return (
    <div css={{margin: '50px', textAlign: 'center'}}>
      <p>Coming soon! Hold tight, we're doing a rebuild and bringing this back better than ever.</p>
    </div>
  );
}
