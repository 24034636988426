import * as d3 from 'd3';
import styled from 'styled-components';
import React, {useEffect} from 'react';
import * as helpers from '../lib/Helpers.js';

const Wrapper = styled.section`
  #chart_div,
  >>> .x_axis text {
    font-family: 'Helvetica Neue', Helvetica, sans-serif;
    font-size: 14px;
    color: #333;
  }

  >>> .selection {
    fill: steelblue;
    fill-opacity: 0.5;
    stroke: steelblue;
  }

  #chart_div {
    background-color: black;
    width: 100%;
    height: 100vh;
    margin-left: auto;
    margin-right: auto;
  }

  #chart_C {
    width: 100%;
    height: 100vh;
    margin-left: auto;
    margin-right: auto;
  }

  >>> .y_axis text,
  >>> .AB_y_axis text,
  >>> .AB_x_axis text {
    fill: white;
  }

  >>> .y_axis path,
  >>> .y_axis line {
    display: none;
  }

  >>> .AB_y_axis path,
  >>> .AB_y_axis line {
    stroke: white;
  }

  >>> .gridline {
    stroke: grey;
    stroke: 1;
    opacity: 0.2;
  }

  >>> #info_rect {
    stroke: green;
    stroke-width: 1px;
  }

  >>> .hover_text {
    text-anchor: start;
    font-size: 0.8em;
    fill: white;
  }

  .plus {
    -webkit-animation: pulse 2s linear infinite;
    -webkit-animation: pulse 2s linear infinite;
    -o-animation: pulse 2s linear infinite;
    animation: pulse 2s linear infinite;
  }
  @keyframes pulse {
    0% {
      -webkit-transform: scale(1);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(2);
      opacity: 0;
    }
  }
  @-webkit-keyframes pulse {
    0% {
      -webkit-transform: scale(1);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(2);
      opacity: 0;
    }
  }
`;

function update_charts(edata, x, y, rlens, abcData) {
  var Plotly = require('plotly.js-dist');
  // annotate the charts on click or hover
  let chartDiv = 'chart_C';
  var r = 0;
  var theta = 0;

  var delta = 1;

  r = Math.round(100 * helpers.get_r(x, y)) / 100;

  if (r >= rlens) {
    return;
  }

  theta = helpers.get_theta(x, y);

  let shapes = helpers.get_c_shapes(x, y, delta, abcData);

  let figures = helpers.get_ab_figures(abcData, r, theta);
  shapes.push(...figures.a.layout.shapes);
  shapes.push(...figures.b.layout.shapes);
  let data = helpers.add_data(figures);

  let gd = document.getElementById(chartDiv);

  let layout = gd.layout;

  layout.annotations.length = 1;
  layout.annotations.push(...figures.a.layout.annotations);
  layout.annotations.push(...figures.b.layout.annotations);

  figures.a.layout.yaxis.domain = [0.8, 1];
  figures.b.layout.yaxis.domain = [0.6, 0.8];

  layout.yaxis2 = figures.b.layout.yaxis;
  layout.yaxis3 = figures.a.layout.yaxis;

  layout.shapes = shapes;
  console.time('react');
  //prepend the heatmap data
  data.unshift(gd.data[0]);

  Plotly.react(chartDiv, data, layout);
  console.timeEnd('react');
}

async function render_plots(abcData, depressionColor, simulationColor) {
  let rlens = helpers.get_rlens(abcData);
  var myPlot = document.getElementById('chart_C');
  var Plotly = require('plotly.js-dist');
  Plotly.purge(myPlot);
  // calculate once per render, used to determine ymax for plot A and B.
  let ic = helpers.initialChord(abcData);
  let figC = helpers.heatmap_figure(abcData, depressionColor, simulationColor);

  var r = 0;
  var theta = 0;

  var delta = 1;
  let x = ic[0];
  let y = ic[1];
  r = Math.round(100 * helpers.get_r(x, y)) / 100;
  theta = helpers.get_theta(x, y);

  let figures = helpers.get_ab_figures(abcData, r, theta);
  figures.c = figC;

  let figb = figures.b;
  let figa = figures.a;

  let data = helpers.add_data(figures);

  figC.layout.yaxis.domain = [0, 0.6];
  figa.layout.yaxis.domain = [0.8, 1];
  figb.layout.yaxis.domain = [0.6, 0.8];

  let layout = {
    grid: {
      rows: 3,
      columns: 1,
      shared_xaxes: true,
      pattern: 'coupled',
    },
    xaxis: {
      ticks: '',
      showticklabels: false,
    },
    yaxis2: figb.layout.yaxis,
    yaxis3: figa.layout.yaxis,
    yaxis: figC.layout.yaxis,
    plot_bgcolor: 'black',
    paper_bgcolor: 'black',
    dragmode: false,
    margin: {t: 0, b: 0, l: 10, r: 40},
    annotations: figC.layout.annotations,
  };

  layout.shapes = helpers.get_c_shapes(x, y, delta, abcData);
  layout.annotations.push(...figa.layout.annotations);
  layout.annotations.push(...figb.layout.annotations);
  layout.shapes.push(...figa.layout.shapes);
  layout.shapes.push(...figb.layout.shapes);

  Plotly.react(myPlot, data, layout, {
    responsive: true,
    displayModeBar: false,
    doubleClick: false,
  });
  myPlot.on('plotly_click', function(data) {
    if (data.points[0].curveNumber != 0) return;
    console.time('update charts');
    update_charts(data, data.points[0].x, data.points[0].y, rlens, abcData);
    console.timeEnd('update charts');
  });

  var dragLayer = document.getElementsByClassName('nsewdrag')[0];

  myPlot.on('plotly_hover', function(data) {
    dragLayer.style.cursor = 'pointer';
  });

  myPlot.on('plotly_unhover', function(data) {
    dragLayer.style.cursor = '';
  });
}

function Simulation(props) {
  useEffect(() => {
    render_plots(props.abcData, props.depressionColor, props.simulationColor);
  }, [props.abcData, props.depressionColor, props.simulationColor]);

  return (
    <Wrapper>
      <div id="chart_div">
        <div id="chart_C"></div>
      </div>
    </Wrapper>
  );
}
export default Simulation;
