/** @jsx jsx */
import React, {useState, useEffect} from 'react';
import CornealData from './CornealData';
import LensParameters from './LensParameters';
import styled from 'styled-components';
import Simulation from '../sim';
import YAML from 'yaml';
import LinearProgress from '@material-ui/core/LinearProgress';
import Button from '@material-ui/core/Button';
import PlayArrow from '@material-ui/icons/PlayArrow';
import {jsx} from '@emotion/core';

let depressionColor = 'rgba(1,1,1,1)';
let simulationColor = 'rgba(0,200,0,1)';

let lens1 = `
- r: [7.7]
  e: [0.5]
  d: 8.3
- r: [8.3]
  e: [0.5]
  d: 8.8
- r: [9.6]
  e: [0.5]
  d: 9.8
`;

let cornea1 = `
- r: [8.0, 8.0]
  e: [0.5, 0.5]
  d: 12.0
`;

const Wrapper = styled.div`
  margin: 0px;
  padding: 0px;
  .grid {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    padding-top: 0px;
    margin: 0px;
    padding: 0px;
  }
  .col {
    width: 50%;
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 0px;
  }
  .pane {
    width: 100%;
    margin: 10px;
  }
  .sim {
    background: black;
  }

  @media only screen and (min-width: 800px) {
    .pane {
      width: 50%;
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

export default function(props) {
  const [lensParams, setLensParams] = useState(YAML.parse(lens1));
  const [cornealParams, setCornealParams] = useState(YAML.parse(cornea1));
  const [flatAxis, setFlatAxis] = useState(180);
  const [params, setParams] = useState(null);
  const [abcData, setAbcData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async function() {
      let payload = {
        type: 'simk-sim',
        fullspec: lensParams,
        FlatAxis: (Math.PI * flatAxis) / 180,
        cornea: cornealParams,
      };
      setParams(payload);
    })();
  }, [flatAxis, lensParams, cornealParams]);

  async function generateSim() {
    console.log('Generating sim');
    console.log(params);
    setLoading(true);
    //let server = 'http://0.0.0.0:8080';
    let server = '/api/sim-api'
    try {
      let res = await fetch(server, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(params),
      });
      if (res.status == 200) {
        console.log(res);
        let js = await res.json();
        console.log(js);
        setAbcData(js.abc_data);
        setLoading(false);
      } else {
        console.log('Error found');
        console.log(res);
        setAbcData(null);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setAbcData(null);
      setLoading(false);
    }
  }

  return (
    <Wrapper>
      <div className="grid">
        <div className="col">
          <div css={{}}>
            <CornealData
              value={cornealParams}
              setValue={val => {
                setCornealParams(val);
              }}
              flatAxis={flatAxis}
              setFlatAxis={val => {
                console.log('Setting flat axis: ' + val);
                setFlatAxis(val);
              }}
            />
          </div>
          <div css={{marginTop: '50px'}}>
            <LensParameters
              value={lensParams}
              setValue={val => {
                setLensParams(val);
              }}
            />
          </div>
          <div
            css={{
              textAlign: 'right',
            }}>
            <Button
              variant="contained"
              color="primary"
              onClick={generateSim}
              disabled={loading}
              css={{
                width: '150px',
              }}
              startIcon={<PlayArrow />}>
              Simulate
            </Button>
          </div>
        </div>
        <div className="col sim">
          {loading ? <LinearProgress color="primary" /> : null}
          {abcData ? (
            <Simulation
              abcData={abcData}
              depressionColor={depressionColor}
              simulationColor={simulationColor}></Simulation>
          ) : null}
        </div>
      </div>
    </Wrapper>
  );
}
