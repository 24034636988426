/** @jsx jsx */
import React, {useState, useEffect, useContext} from 'react';
import NumberField from './NumberField';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import Delete from '@material-ui/icons/Delete';
import Add from '@material-ui/icons/Add';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {jsx} from '@emotion/core';

let buttonStyle = {
  marginBottom: '-35px',
};

let RS = function(props) {
  let colStyle = {
    width: '30%',
    paddingRight: '10px',
    paddingLeft: '10px',
    paddingBottom: '10px',
  };
  let deleteCol = {
    width: '10%',
    paddingRight: '10px',
    paddingLeft: '10px',
    paddingBottom: '10px',
  };
  return (
    <div css={{display: 'flex', flexWrap: 'wrap', width: '100%'}}>
      <div css={colStyle}>
        <NumberField
          value={props.vals['r'][0]}
          label="R"
          suffix="mm"
          onChange={e => {
            let newParams = JSON.parse(JSON.stringify(props.vals));
            newParams['r'][0] = parseFloat(e.target.value);
            props.setVals(newParams);
          }}
          min="0"
          max="10"
          step="0.05"
        />
      </div>

      <div css={colStyle}>
        <NumberField
          value={props.vals['e'][0]}
          label="e"
          suffix=""
          onChange={e => {
            let newParams = JSON.parse(JSON.stringify(props.vals));
            newParams['e'][0] = parseFloat(e.target.value);
            props.setVals(newParams);
          }}
          min="0"
          max="1"
          step="0.05"
        />
      </div>

      <div css={colStyle}>
        <NumberField
          value={props.vals['d']}
          label="Diameter"
          suffix="mm"
          onChange={e => {
            let newParams = JSON.parse(JSON.stringify(props.vals));
            newParams['d'] = parseFloat(e.target.value);
            props.setVals(newParams);
          }}
          min="0"
          max="10"
          step="0.05"
        />
      </div>
      <div css={deleteCol}>
        <IconButton
          onClick={e => {
            props.deleteZone();
          }}
          css={buttonStyle}
          component="span">
          <Delete />
        </IconButton>
      </div>
    </div>
  );
};

let Toric = function(props) {
  let colStyle = {
    width: '18%',
    paddingRight: '10px',
    paddingLeft: '10px',
    paddingBottom: '10px',
  };
  let deleteCol = {
    width: '10%',
    paddingRight: '10px',
    paddingLeft: '10px',
    paddingBottom: '10px',
  };
  return (
    <div css={{display: 'flex', flexWrap: 'wrap', width: '100%'}}>
      <div css={colStyle}>
        <NumberField
          value={props.vals['r'][0]}
          label="R1"
          suffix="mm"
          onChange={e => {
            let newParams = JSON.parse(JSON.stringify(props.vals));
            newParams['r'][0] = parseFloat(e.target.value);
            props.setVals(newParams);
          }}
          min="0"
          max="10"
          step="0.05"
        />
      </div>
      <div css={colStyle}>
        <NumberField
          value={props.vals['r'][1]}
          label="R2"
          suffix="mm"
          onChange={e => {
            let newParams = JSON.parse(JSON.stringify(props.vals));
            newParams['r'][1] = parseFloat(e.target.value);
            props.setVals(newParams);
          }}
          min="0"
          max="10"
          step="0.05"
        />
      </div>

      <div css={colStyle}>
        <NumberField
          value={props.vals['e'][0]}
          label="e1"
          suffix=""
          onChange={e => {
            let newParams = JSON.parse(JSON.stringify(props.vals));
            newParams['e'][0] = parseFloat(e.target.value);
            props.setVals(newParams);
          }}
          min="0"
          max="1"
          step="0.05"
        />
      </div>

      <div css={colStyle}>
        <NumberField
          value={props.vals['e'][1]}
          label="e2"
          suffix=""
          onChange={e => {
            let newParams = JSON.parse(JSON.stringify(props.vals));
            newParams['e'][1] = parseFloat(e.target.value);
            props.setVals(newParams);
          }}
          min="0"
          max="1"
          step="0.05"
        />
      </div>

      <div css={colStyle}>
        <NumberField
          value={props.vals['d']}
          label="Diameter"
          suffix="mm"
          onChange={e => {
            let newParams = JSON.parse(JSON.stringify(props.vals));
            newParams['d'] = parseFloat(e.target.value);
            props.setVals(newParams);
          }}
          min="0"
          max="10"
          step="0.05"
        />
      </div>
      <div css={deleteCol}>
        <IconButton
          onClick={e => {
            props.deleteZone();
          }}
          css={buttonStyle}
          component="span">
          <Delete />
        </IconButton>
      </div>
    </div>
  );
};

export {RS, Toric};
